import {
  Box,
  Button,
  Collapse,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { IConfig } from '../../../../../../Interfaces/IConfig';
import { IInventoryOption } from '../../../../../../Interfaces/IInventoryOption';
import { IPurchaseContractHasPerson } from '../../../../../../Interfaces/IPerson';
import { IPropertyType } from '../../../../../../Interfaces/IPropertyType';
import { IRealEstate } from '../../../../../../Interfaces/IRealEstate';
import { IRealEstateHasInventoryOption } from '../../../../../../Interfaces/IRealEstateHasInventoryOption';
import { IRealEstateHasPropertyType } from '../../../../../../Interfaces/IRealEstateHasPropertyType';
import { IRealEstateUseType } from '../../../../../../Interfaces/IRealEstateUseType';
import { CustomeSlide } from '../../../../../core/CustomeSlide';
import { DialogResponsive } from '../../../../../core/DialogResponsive';
import { getIsValidatedAdress } from './functions_validated';
import { NewRealEstatePartialArea } from './NewRealEstatePartialArea';
import { searchRealEstate } from './PurchaseContractRealEstateEdit';
import { RealEstateAddress } from './RealEstateAddress';
import { RealEstateInventoryOptions } from './RealEstateInventoryOptions';
import { RealEstateLandRegister } from './RealEstateLandRegister';
import { RealEstateManager } from './RealEstateManager';
import { RealEstatePriceUseType } from './RealEstatePriceUseType';
import { RealEstatePropertyTypes } from './RealEstatePropertyTypes';

interface IProps {
  configObject: IConfig;
  realEstateArray: IRealEstate[];
  setRealEstateArray: Function;
  realEstateHasPropertyTypeArray: IRealEstateHasPropertyType[];
  setRealEstateHasPropertyTypeArray: Function;
  propertyTypeArray: IPropertyType[];
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  setRealEstateHasInventroyOptionArray: Function;
  inventoryOptionArray: IInventoryOption[];
  realEstateUseTypeArray: IRealEstateUseType[];
  personArray: IPurchaseContractHasPerson[];
  setPersonArray: Function;
}

export const NewRealEstateDialog: React.FC<IProps> = props => {
  const [currentStep, setCurrentStep] = useState(0);
  const [lastStep, setLastStep] = useState(-1);
  //
  const [newObject, setNewObject] = useState<IRealEstate>(
    searchRealEstate(-1, props.realEstateArray, true)
  );
  const [idRealEstate, setIdRealEstate] = useState(newObject.idRealEstate);
  const [realEstateHasPropertyTypeArray, setRealEstateHasPropertyTypeArray] =
    useState<IRealEstateHasPropertyType[]>([]);
  const [
    realEstateHasInventroyOptionArray,
    setRealEstateHasInventroyOptionArray,
  ] = useState<IRealEstateHasInventoryOption[]>([]);
  //
  const [isOpenNew, setIsOpenNew] = useState(false);
  //
  const [wasSetIsBuild, setWasSetIsBuild] = useState(false);
  const [wasSetHasApartment, setWasSetHasApartment] = useState(false);
  const [hasWarningPirce, setHasWarningPrice] = useState(false);

  const handleOpen = () => {
    const newestObject = searchRealEstate(-1, props.realEstateArray, true);
    setNewObject(newestObject);
    setIdRealEstate(newestObject.idRealEstate);
    setRealEstateHasPropertyTypeArray([]);
    setRealEstateHasInventroyOptionArray([]);
    setCurrentStep(0);
    setWasSetIsBuild(false);
    setIsOpenNew(true);
  };

  const handleNext = () => {
    setLastStep(currentStep);

    if (
      currentStep === 3 &&
      realEstateHasPropertyTypeArray.length === 1 &&
      realEstateHasPropertyTypeArray[0].idPropertyType === 2
    ) {
      setCurrentStep(5);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    const tmpStep = currentStep;

    if (lastStep > currentStep) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(lastStep);
    }

    setLastStep(tmpStep);
  };

  const handleAdd = () => {
    props.setRealEstateArray([...props.realEstateArray, newObject]);

    props.setRealEstateHasPropertyTypeArray([
      ...props.realEstateHasPropertyTypeArray,
      ...realEstateHasPropertyTypeArray,
    ]);

    props.setRealEstateHasInventroyOptionArray([
      ...props.realEstateHasInventroyOptionArray,
      ...realEstateHasInventroyOptionArray,
    ]);

    setIsOpenNew(false);
  };

  return (
    <>
      <DialogResponsive
        open={isOpenNew}
        //onClose={() => setIsOpenNew(false)}
        onClose={() => {}}
        maxWidth='lg'
        title='Objekt hinzufügen'
      >
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '20%' }}>
            <Stepper activeStep={currentStep} orientation='vertical'>
              <Step key={`purchaseContract-0`} completed={currentStep > 0}>
                <StepLabel>Anschrift</StepLabel>
              </Step>
              <Step key={`purchaseContract-1`} completed={currentStep > 1}>
                <StepLabel>Wohn- oder Teileigentum</StepLabel>
              </Step>
              <Step key={`purchaseContract-1`} completed={currentStep > 2}>
                <StepLabel>Grundbuch</StepLabel>
              </Step>
              <Step key={`purchaseContract-2`} completed={currentStep > 3}>
                <StepLabel>Grundstücksarten</StepLabel>
              </Step>
              <Step key={`purchaseContract-3`} completed={currentStep > 4}>
                <StepLabel>Bauten</StepLabel>
              </Step>
              <Step key={`purchaseContract-2`} completed={currentStep > 5}>
                <StepLabel>Teilfläche</StepLabel>
              </Step>
              <Step key={`purchaseContract-4`} completed={currentStep > 6}>
                <StepLabel>Inventar und Anlagen</StepLabel>
              </Step>
              <Step key={`purchaseContract-4`} completed={currentStep > 7}>
                <StepLabel>Nutzung</StepLabel>
              </Step>
            </Stepper>
          </Box>

          <Box sx={{ width: '80%' }}>
            <CustomeSlide
              currentPage={currentStep}
              pageNumber={0}
              lastCurrentPage={lastStep}
              appear={false}
            >
              <Box>
                <RealEstateAddress
                  configObject={props.configObject}
                  currentObject={newObject}
                  setCurrentObject={setNewObject}
                  marginTop={0}
                />
                <Box sx={{ mt: 5 }}>
                  <Button
                    variant='outlined'
                    onClick={() => setIsOpenNew(false)}
                  >
                    Abrruch
                  </Button>

                  <Button
                    onClick={handleNext}
                    variant='contained'
                    sx={{ float: 'right' }}
                    disabled={!getIsValidatedAdress(newObject)}
                  >
                    Weiter
                  </Button>
                </Box>
              </Box>
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={1}
              lastCurrentPage={lastStep}
            >
              <Box>
                <Box
                  display='flex'
                  height={80}
                  alignItems='center'
                  justifyContent='center'
                >
                  Liegt Wohn- oder Teileigentum vor?
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    variant={
                      wasSetHasApartment === false || newObject.hasApartment
                        ? 'outlined'
                        : 'contained'
                    }
                    onClick={() => {
                      setWasSetHasApartment(true);
                      setNewObject({ ...newObject, hasApartment: false });
                    }}
                    sx={{ mr: 1 }}
                  >
                    Nein
                  </Button>

                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button
                    variant={
                      wasSetHasApartment === false || !newObject.hasApartment
                        ? 'outlined'
                        : 'contained'
                    }
                    onClick={() => {
                      setWasSetHasApartment(true);
                      setNewObject({ ...newObject, hasApartment: true });
                    }}
                  >
                    Ja
                  </Button>
                </Box>

                <Collapse in={wasSetHasApartment && newObject.hasApartment}>
                  <RealEstateManager
                    realEstateOnject={newObject}
                    setRealEstateObject={setNewObject}
                    personArray={props.personArray}
                    setPersonArray={props.setPersonArray}
                  />
                </Collapse>

                <Box sx={{ mt: 5 }}>
                  <Button variant='outlined' onClick={handleBack}>
                    Zurück
                  </Button>

                  <Button
                    onClick={handleNext}
                    variant='contained'
                    sx={{ float: 'right' }}
                    disabled={wasSetHasApartment === false}
                  >
                    Weiter
                  </Button>
                </Box>
              </Box>
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={2}
              lastCurrentPage={lastStep}
            >
              <Box>
                <RealEstateLandRegister
                  currentObject={newObject}
                  setCurrentObject={setNewObject}
                  marginTop={0}
                />
                <Box sx={{ mt: 5 }}>
                  <Button variant='outlined' onClick={handleBack}>
                    Zurück
                  </Button>

                  <Button
                    onClick={handleNext}
                    variant='contained'
                    sx={{ float: 'right' }}
                  >
                    Weiter
                  </Button>
                </Box>
              </Box>
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={3}
              lastCurrentPage={lastStep}
            >
              <Box>
                <RealEstatePropertyTypes
                  idRealEstate={idRealEstate}
                  realEstateHasPropertyTypeArray={
                    realEstateHasPropertyTypeArray
                  }
                  setRealEstateHasPropertyTypeArray={
                    setRealEstateHasPropertyTypeArray
                  }
                  propertyTypeArray={props.propertyTypeArray}
                  marginTop={0}
                />
                <Box sx={{ mt: 5 }}>
                  <Button variant='outlined' onClick={handleBack}>
                    Zurück
                  </Button>

                  <Button
                    onClick={handleNext}
                    disabled={realEstateHasPropertyTypeArray.length === 0}
                    variant='contained'
                    sx={{ float: 'right' }}
                  >
                    Weiter
                  </Button>
                </Box>
              </Box>
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={4}
              lastCurrentPage={lastStep}
            >
              <Box>
                <Collapse
                  in={
                    realEstateHasPropertyTypeArray.find(
                      x => x.idPropertyType === 1
                    ) === undefined
                  }
                  appear={false}
                >
                  <Box
                    display='flex'
                    height={80}
                    alignItems='center'
                    justifyContent='center'
                  >
                    Ist das Grundstück (oder ein Teil davon) bebaut?
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      variant={
                        wasSetIsBuild === false || newObject.isBuild
                          ? 'outlined'
                          : 'contained'
                      }
                      onClick={() => {
                        setWasSetIsBuild(true);
                        setNewObject({
                          ...newObject,
                          isBuild: false,
                          BuildWith: null,
                        });
                      }}
                      sx={{ mr: 1 }}
                    >
                      Unbebaut
                    </Button>

                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                      variant={
                        wasSetIsBuild === false || !newObject.isBuild
                          ? 'outlined'
                          : 'contained'
                      }
                      onClick={() => {
                        setWasSetIsBuild(true);
                        setNewObject({ ...newObject, isBuild: true });
                      }}
                    >
                      Bebaut
                    </Button>
                  </Box>
                </Collapse>

                <Collapse
                  in={
                    (wasSetIsBuild && newObject.isBuild) ||
                    realEstateHasPropertyTypeArray.find(
                      x => x.idPropertyType === 1
                    ) !== undefined
                  }
                >
                  <TextField
                    sx={{ mt: 3 }}
                    label='Bauten (Häuser, Schuppen, Brunnen etc.)'
                    size='small'
                    fullWidth
                    value={
                      newObject.BuildWith === null ? '' : newObject.BuildWith
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setNewObject({
                        ...newObject,
                        BuildWith:
                          event.target.value === '' ? null : event.target.value,
                      })
                    }
                    multiline
                    rows={3}
                  />
                </Collapse>

                <Box sx={{ mt: 5 }}>
                  <Button variant='outlined' onClick={handleBack}>
                    Zurück
                  </Button>

                  <Button
                    onClick={handleNext}
                    variant='contained'
                    sx={{ float: 'right' }}
                    disabled={
                      wasSetIsBuild === false &&
                      realEstateHasPropertyTypeArray.find(
                        x => x.idPropertyType === 1
                      ) === undefined
                    }
                  >
                    Weiter
                  </Button>
                </Box>
              </Box>
            </CustomeSlide>

            <NewRealEstatePartialArea
              pageNumber={5}
              currentStep={currentStep}
              lastStep={lastStep}
              handleBack={handleBack}
              handleNext={handleNext}
              newObject={newObject}
              setNewObject={setNewObject}
            />

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={6}
              lastCurrentPage={lastStep}
            >
              <Box>
                <RealEstateInventoryOptions
                  idRealEstate={idRealEstate}
                  inventoryOptionArray={props.inventoryOptionArray}
                  realEstateHasInventroyOptionArray={
                    realEstateHasInventroyOptionArray
                  }
                  setRealEstateHasInventroyOptionArray={
                    setRealEstateHasInventroyOptionArray
                  }
                  marginTop={0}
                />
                <Box sx={{ mt: 5 }}>
                  <Button variant='outlined' onClick={handleBack}>
                    Zurück
                  </Button>

                  <Button
                    onClick={handleNext}
                    disabled={realEstateHasPropertyTypeArray.length === 0}
                    variant='contained'
                    sx={{ float: 'right' }}
                  >
                    Weiter
                  </Button>
                </Box>
              </Box>
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={7}
              lastCurrentPage={lastStep}
            >
              <Box>
                <RealEstatePriceUseType
                  currentObject={newObject}
                  setCurrentObject={setNewObject}
                  realEstateUseTypeArray={props.realEstateUseTypeArray}
                  realEstateHasInventroyOptionArray={
                    realEstateHasInventroyOptionArray
                  }
                  setHasWarningPrice={setHasWarningPrice}
                  marginTop={0}
                />
                <Box sx={{ mt: 5 }}>
                  <Button variant='outlined' onClick={handleBack}>
                    Zurück
                  </Button>

                  <Button
                    onClick={handleAdd}
                    disabled={hasWarningPirce}
                    variant='contained'
                    sx={{ float: 'right' }}
                  >
                    Hinzufügen
                  </Button>
                </Box>
              </Box>
            </CustomeSlide>
          </Box>
        </Box>
      </DialogResponsive>

      <Button sx={{ float: 'right' }} variant='contained' onClick={handleOpen}>
        Objekt hinzufügen
      </Button>
    </>
  );
};
