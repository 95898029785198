import {
  AppBar,
  Box,
  Button,
  Drawer,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Send from '../../../generic/Send';
import { SaveProgress } from '../SaveProgress';
import { RestoreProgress } from '../RestoreProgress';
import { IPersonHasDocument } from '../../../../Interfaces/IPersonHasDocument';
import { CustomeSlide } from '../../../core/CustomeSlide';
import { Start } from './Childs/Dialog/Start';
import { MortageBaseInformation } from './Childs/Dialog/MortageBaseInformation';
import { useHotkeys } from 'react-hotkeys-hook';
import { IMortgage } from '../../../../Interfaces/IMortgage';
import { MortgageOptions } from './Childs/Dialog/MortgageOptions';
import { MortgageContact } from './Childs/Dialog/MortgageContact';
import { searchRealEstate } from '../PurchaseContract/Components/RealEstate/PurchaseContractRealEstateEdit';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { MortgageDocuments } from './Childs/Dialog/MortgageDocuments';
import { IDocument } from '../../../../Interfaces/IDocument';
import { MortgageBeneficiary } from './Childs/Dialog/MortgageBeneficiary';
import { MortgageGeneral } from './Childs/Dialog/MortgageGeneral';
import { IConfig } from '../../../../Interfaces/IConfig';
import { MortgageSummary } from './Childs/Dialog/MortgageSummary';
import { IPerson } from '../../../../Interfaces/IPerson';

interface IProps {
  configObject: IConfig;
}

export const MortgageDialog: React.FC<IProps> = props => {
  const [currentStep, setCurrentStep] = useState(0);
  const [lastCurrentStep, setLastCurrentStep] = useState(0);
  const [activeStepCounter, setActiveStepCounter] = useState(0);

  const [txt, setTxt] = useState<React.ReactNode>(<></>);

  const [newObject, setNewObject] = useState<IRealEstate>(
    searchRealEstate(-1, [], true)
  );

  const [mortgageObject, setMortgageObject] = useState({
    idLegalTransaction: -1,
    RealEstate: newObject,
    idMortgage: -1,
    idPerson: -1,
    ToBeDeleted: null,
    AdditionalServices: '',
  } as IMortgage);

  const [personArray, setPersonArray] = useState<IPerson[]>([]);
  const [documentArray, setDocumentArray] = useState<IDocument[]>([]);
  const [personHasDocument, setPersonHasDocument] = useState<
    IPersonHasDocument[]
  >([]);

  const [isDebugEnabled, setIsDebugEnabled] = useState(false);

  useHotkeys('shift+d', () => setIsDebugEnabled(!isDebugEnabled));

  /* ###############################  BEGIN SAVE AND RESTORE ############################### */
  const getDataToSave = () => {
    const tempDataToSave = {
      CurrentStep: currentStep,
      LastCurrentStep: lastCurrentStep,
      ActiveStepCounter: activeStepCounter,
      //Txt: txt,
      MortgageObject: mortgageObject,
      PersonArray: personArray,
      PersonHasDocument: personHasDocument,
      DocumentArray: documentArray,
    };
    return tempDataToSave;
  };

  const [dataToSave, setDataToSave] = useState(getDataToSave);

  useEffect(() => {
    setMortgageObject({ ...mortgageObject, DocumentArray: documentArray });
  }, [documentArray]);

  const restoreSetterFunction = (uplodatedFile: any) => {
    if ('CurrentStep' in uplodatedFile) {
      setCurrentStep(uplodatedFile['CurrentStep']);
    }
    if ('LastCurrentStep' in uplodatedFile) {
      setLastCurrentStep(uplodatedFile['LastCurrentStep']);
    }
    if ('ActiveStepCounter' in uplodatedFile) {
      setActiveStepCounter(uplodatedFile['ActiveStepCounter']);
    }
    if ('Txt' in uplodatedFile) {
      setTxt(uplodatedFile['Txt']);
    }
    if (
      'MortgageObject' in uplodatedFile &&
      uplodatedFile['MortgageObject'] !== mortgageObject
    ) {
      setMortgageObject(uplodatedFile['MortgageObject']);
    }
    if (
      'PersonArray' in uplodatedFile &&
      uplodatedFile['PersonArray'] !== personArray
    ) {
      setPersonArray(uplodatedFile['PersonArray']);
    }
    if (
      'PersonHasDocument' in uplodatedFile &&
      uplodatedFile['PersonHasDocument'] !== personHasDocument
    ) {
      setPersonHasDocument(uplodatedFile['PersonHasDocument']);
    }
    if (
      'DocumentArray' in uplodatedFile &&
      uplodatedFile['DocumentArray'] !== personHasDocument
    ) {
      setDocumentArray(uplodatedFile['DocumentArray']);
    }
  };

  /* ###############################  END SAVE AND RESTORE ############################### */

  useEffect(() => {
    console.log(mortgageObject);
  });

  const handleNext = (nextPage?: number) => {
    setLastCurrentStep(currentStep);

    // Prüfen, ob ToBeDeleted true ist und der nächste Schritt 3 wäre
    if (
      mortgageObject.ToBeDeleted &&
      (nextPage === undefined ? currentStep + 1 : nextPage) === 3
    ) {
      // Direkt zu Schritt 4 springen
      setCurrentStep(4);
    } else {
      // Normaler Ablauf
      if (nextPage === undefined) {
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(nextPage);
      }
    }
  };

  const handleBack = () => {
    const tmpStep = currentStep;

    // Prüfen, ob ToBeDeleted true ist und der vorherige Schritt 3 wäre
    if (mortgageObject.ToBeDeleted && tmpStep === 4 && lastCurrentStep === 3) {
      setCurrentStep(2); // Direkt zu Schritt 2 zurückspringen
    } else if (mortgageObject.ToBeDeleted && tmpStep === 3) {
      setCurrentStep(2); // Direkt zu Schritt 2 zurückspringen
    } else {
      // Normaler Rückwärts-Navigationslogik
      if (tmpStep - lastCurrentStep > 1) {
        setCurrentStep(lastCurrentStep);
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
    setLastCurrentStep(currentStep);
  };

  useEffect(() => {
    setDataToSave(getDataToSave());
  }, [
    personArray,
    personHasDocument,
    mortgageObject,
    currentStep,
    lastCurrentStep,
    documentArray,
  ]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: 250,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 250,
              boxSizing: 'border-box',
            },
          }}
          variant='persistent'
          anchor='left'
          open={true}
        >
          <Box sx={{ p: 2, display: 'flex' }}>
            <Stepper activeStep={currentStep} orientation='vertical'>
              <Step key={`mortgage-0`} completed={currentStep > 0}>
                <StepLabel>Grundschuld</StepLabel>
              </Step>
              <Step key={`mortgage-1`} completed={currentStep > 1}>
                <StepLabel>Eigentümer</StepLabel>
              </Step>
              <Step key={`mortgage-2`} completed={currentStep > 2}>
                <StepLabel>Bestellen / Löschen</StepLabel>
              </Step>
              <Step key={`mortgage-3`} completed={currentStep > 3}>
                <StepLabel>Grundpfand</StepLabel>
              </Step>
              <Step key={`mortgage-4`} completed={currentStep > 4}>
                <StepLabel>Gläubiger</StepLabel>
              </Step>
              <Step key={`mortgage-5`} completed={currentStep > 5}>
                <StepLabel>Dokumente</StepLabel>
              </Step>
              <Step key={`mortgage-6`} completed={currentStep > 6}>
                <StepLabel>Zusammenfassung</StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Drawer>

        <Box sx={{ flexGrow: 1, width: { sm: `calc(100% - ${250}px)` } }}>
          <AppBar position='static'>
            <Toolbar>
              <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                Formular zur Grundschuld
              </Typography>
              <RestoreProgress
                key='mortgage-import'
                setterFunction={restoreSetterFunction}
              ></RestoreProgress>
              <SaveProgress
                key='mortgage-export'
                idLegaltransaction={100}
                dataToSave={dataToSave}
                filename={'Grundschuldantrag'}
              ></SaveProgress>
            </Toolbar>
          </AppBar>

          <Box sx={{ p: 3 }}>
            <img
              width={250}
              src={`data:${props.configObject.logo_mime};base64,${props.configObject.logo}`}
            />

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={0}
              lastCurrentPage={lastCurrentStep}
              appear={false}
            >
              <Start
                currentStep={currentStep}
                setcurrentStep={setCurrentStep}
                handleNext={handleNext}
              />
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={1}
              lastCurrentPage={lastCurrentStep}
            >
              <MortageBaseInformation
                personArray={personArray}
                setPersonArray={setPersonArray}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                mortgageObject={mortgageObject}
                setMortgageObject={setMortgageObject}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={2}
              lastCurrentPage={lastCurrentStep}
            >
              <MortgageOptions
                configObject={props.configObject}
                mortgageObject={mortgageObject}
                setMortgageObject={setMortgageObject}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                handleBack={handleBack}
                handleNext={handleNext}
                personArray={personArray}
                setPersonArray={setPersonArray}
              />
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={3}
              lastCurrentPage={lastCurrentStep}
            >
              <MortgageGeneral
                setMortgageObject={setMortgageObject}
                mortgageObject={mortgageObject}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={4}
              lastCurrentPage={lastCurrentStep}
            >
              <MortgageBeneficiary
                setMortgageObject={setMortgageObject}
                mortgageObject={mortgageObject}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={5}
              lastCurrentPage={lastCurrentStep}
            >
              <MortgageDocuments
                setMortgageObject={setMortgageObject}
                mortgageObject={mortgageObject}
                documentArray={documentArray}
                setDocumentArray={setDocumentArray}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={6}
              lastCurrentPage={lastCurrentStep}
            >
              <>
                <MortgageSummary
                  mortgageObject={mortgageObject}
                  setMortgageObject={setMortgageObject}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  realEstate={mortgageObject.RealEstate}
                  personArray={personArray}
                  documentArray={documentArray}
                  configObject={props.configObject}
                />

                <MortgageContact
                  mortgageObject={mortgageObject}
                  setMortgageObject={setMortgageObject}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  personArray={personArray}
                  personHasDocument={personHasDocument}
                  configObject={props.configObject}
                />
              </>
            </CustomeSlide>

            <CustomeSlide
              currentPage={currentStep}
              pageNumber={7}
              lastCurrentPage={lastCurrentStep}
            >
              <Send />
            </CustomeSlide>

            {isDebugEnabled && (
              <>
                <b>DEBUG</b>
                <br />
                {`currentPage: ${currentStep}`}
                <br />
                <Button onClick={() => setCurrentStep(1)}>
                  Zurück zum Start
                </Button>
              </>
            )}
          </Box>
        </Box>

        <Drawer
          sx={{
            width: 250,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 250,
              boxSizing: 'border-box',
            },
          }}
          variant='persistent'
          anchor='right'
          open={true}
        >
          <Box sx={{ p: 2 }}>{txt}</Box>
        </Drawer>
      </Box>
    </>
  );
};
